import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Refer from "../components/Forms/Refer";

export default class ReferralPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Refer a Friend" />
        <Refer />
      </Fragment>
    );
  }
}

import React, { PureComponent } from "react";
import cx from "classnames";
import { navigate } from "gatsby";

import Navbar from "../../Navbar";
import Footer from "../../Footer";

import styles from "../Common.module.scss";
import formStyles from "../Form.module.scss";

import ReferImg from "../../../img/refer.jpg";

export default class Refer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
      const loaderElem = document.getElementById("loader");
      if (loaderElem) {
        loaderElem.parentNode.removeChild(loaderElem);
      }
    }, 0);
  }

  resetValidation = e => {
    e.target.setCustomValidity("");
  };

  render() {
    const { loaded } = this.state;
    return (
      <div className={cx(styles.FormPage, { [styles.Loaded]: loaded })}>
        <Navbar
          goTo={page => {
            navigate(`/#${page}`);
          }}
          pathname="refer"
        />
        <div className={cx(styles.Page, { [styles.Active]: loaded })}>
          <div>
            <div className={styles.Content}>
              <div>
                <img src={ReferImg} />
                <div className={styles.BackgroundOverlay} />
              </div>
              <div className={styles.ContentText}>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ maxWidth: "250px" }}>
                    <h3 style={{ margin: 0, whiteSpace: "nowrap" }}>
                      Thanks for
                    </h3>
                    <h3 style={{ margin: 0, whiteSpace: "nowrap" }}>
                      thinking of us.
                    </h3>
                    <br />
                    <div>
                      <p>
                        To refer a friend and take advantage of our{" "}
                        <b>Give $100 | Get $100 Casi Ari Design Credit</b>.
                        please use this form or email us at{" "}
                        <a
                          style={{ textDecoration: "underline" }}
                          href="mailto:info@casiari.com"
                        >
                          info@casiari.com
                        </a>
                        .
                      </p>
                      <p>
                        Please note, we are only able to provide design credits
                        for referrals who start a project with us.
                      </p>
                      <p>We look forward to serving you!</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.MiniContent}>
                    <h2
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontSize: "1.5em",
                        maxWidth: "55vw"
                      }}
                    >
                      Thanks for thinking of us.
                    </h2>
                  </div>
                  <form
                    name="referral"
                    className={formStyles.Form}
                    method="POST"
                    netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-netlify="true"
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="referral" />
                    <div className={formStyles.FormFieldsWrapper}>
                      <input
                        required
                        onInvalid={e =>
                          e.target.setCustomValidity("Please enter your name.")
                        }
                        onInput={this.resetValidation}
                        name="name"
                        placeholder="My name is:"
                      />
                      <input
                        required
                        onInvalid={e =>
                          e.target.setCustomValidity(
                            "Please enter a valid email."
                          )
                        }
                        onInput={this.resetValidation}
                        name="email"
                        placeholder="My email is:"
                        type="email"
                      />
                    </div>

                    <div className={formStyles.FormFieldsWrapper}>
                      <input
                        // required
                        // onInvalid={e =>
                        //   e.target.setCustomValidity(
                        //     "Please enter a referral contact."
                        //   )
                        // }
                        // onInput={this.resetValidation}
                        name="referral-contact"
                        placeholder="Referral contact:"
                      />
                      <input
                        // required
                        // onInvalid={e =>
                        //   e.target.setCustomValidity(
                        //     "Please enter a valid referral email."
                        //   )
                        // }
                        // onInput={this.resetValidation}
                        name="referral-email"
                        placeholder="Referral email:"
                        type="email"
                      />
                    </div>
                    <div className={formStyles.FormFieldsWrapper}>
                      <textarea
                        // required
                        // onInvalid={e =>
                        //   e.target.setCustomValidity("Please enter the details.")
                        // }
                        // onInput={this.resetValidation}
                        name="details"
                        rows={3}
                        placeholder="Details:"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div
                      className={formStyles.FormFooter}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <button>CONNECT</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer
            goTo={page => {
              navigate(`/#${page}`);
            }}
          />
        </div>
      </div>
    );
  }
}
